<template>
  <v-main>

    <!-- app bar -->
    <v-app-bar app flat dense color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"/>
      <v-app-bar-title>Rankingi RubiART</v-app-bar-title>
      <v-spacer/>
      <v-btn outlined @click="logout()">WYLOGUJ SIĘ</v-btn>
    </v-app-bar>

    <!-- navigation drawer -->
    <v-navigation-drawer app v-model="drawer">
      <v-list dense>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon color="primary">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Panel główny</v-list-item-title>
        </v-list-item>
        <span v-for="group in groups" :key="group.title">
          <v-divider/>
          <v-subheader>{{ group.title }}</v-subheader>
          <v-list-item v-for="option in group.options" :key="option.title" link
                       @click="$router.push(option.route).catch(() => {});">
            <v-list-item-icon>
              <v-icon color="primary">{{ option.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ option.title }}</v-list-item-title>
          </v-list-item>
        </span>
      </v-list>
    </v-navigation-drawer>

    <!-- router view -->
    <router-view/>

  </v-main>
</template>

<script>
export default {
  name: 'AdminApp',
  data: () => ({
    drawer: null,
    groups: [{
      title: 'Personalizacja', options: [
        {title: 'Strona główna', icon: 'mdi-application-edit', route: '/admin'}
      ]
    }, {
      title: 'Rankingi', options: [
        {title: 'Zawodnicy', icon: 'mdi-account-group', route: '/admin'},
        {title: 'Zawody', icon: 'mdi-calendar', route: '/admin'},
        {title: 'Cykle zawodów', icon: 'mdi-calendar-multiple', route: '/admin/rankings/series'},
        {title: 'Punkty', icon: 'mdi-numeric', route: '/admin/points'}
      ]
    }, {
      title: 'Statystyki live', options: [
        {title: 'Zawodnik', icon: 'mdi-account', route: '/admin/stats/competitor'},
        {title: 'Zawody', icon: 'mdi-calendar', route: '/admin/stats/competition'}
      ]
    }, {
      title: 'Narzędzia', options: [
        {title: 'Losowania', icon: 'mdi-slot-machine', route: '/admin'},
        {title: 'OBS', icon: 'mdi-broadcast', route: '/admin/obs'}
      ]
    }, {
      title: 'Inne', options: [
        {title: 'Ustawienia', icon: 'mdi-cog', route: '/admin/settings'}
      ]
    }]
  }),
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/');
    }
  }
};
</script>
